import React, { FC } from 'react';
import { WidgetProps, ReportError } from '@wix/yoshi-flow-editor';
import { DesktopWidgetComponent } from './desktopWidgetComponent';
import { MobileWidgetComponent } from './mobile/mobileWidgetComponent';
import { HandlersWithState } from './worker/controller-handlers';

export type OldWidgetProps = WidgetProps<
  {
    debug: boolean;
    isSSR: boolean;
    fixStudioMobileWidgetEnabled: boolean;
    instance: string;
    isResponsive: boolean;
    captureException: ReportError;
  } & HandlersWithState
>;

export const OldWidget: FC<OldWidgetProps> = (props) => {
  const isResponsive =
    !props.fixStudioMobileWidgetEnabled && props.isResponsive;
  const isDesktop = ['Desktop', 'Tablet'].includes(props.host.formFactor);
  const Component =
    isResponsive || isDesktop ? DesktopWidgetComponent : MobileWidgetComponent;

  return <Component {...props} />;
};
