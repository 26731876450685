import _ from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { VIDEO_CARDS_BI_ORIGIN } from '@wix/wix-vod-constants/bi';
import { withTranslation, withBi } from '@wix/yoshi-flow-editor';
import { isOwned, isLiveVideo } from '@wix/wix-vod-shared/common';
import {
  Spinner,
  ActionsPanel,
  AllVideoCards,
  VideoCards,
  PlayerUIVisibilityManager,
} from '@wix/wix-vod-shared/components';
import {
  canAccessFullVideo,
  canPlayFullOrPreview,
  getOwnershipStatus,
  isDownloadAllowed,
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
  makeCancelable,
} from '@wix/wix-vod-shared/widget';
import {
  isRTL,
  areElementsRounded,
  isCommentsEnabled,
  isCommentsEnabledForLive,
  showVideoQuality,
  showVideoPlaybackSpeed,
} from '../../../selectors/app-settings';
import { isSingleVideo } from '../../../selectors/video';
import { loadVODPlayer } from '../../../utils/load-player';

import { logBi } from '../../../worker/actions/bi';

import { getInstance } from '../../../redux/hydrated-data/hydrated-data';
import { isDownloadEnabled } from '../../../selectors/downloads';
import {
  isInFullScreen,
  isAutoplayed,
  isPlayerUIVisible,
  getOverlayContent,
} from '../../../selectors/player';
import { getBuyRentTextForPlayer } from '../../../utils/buy-button-texts/buy-button-texts';
import getPlayerTranslations from '../../../utils/get-player-translations';
import getVidiUrls, {
  getDownloadUrl as getDownloadUrlGql,
} from '../../../selectors/vidi-urls';
import { notForPreview } from '../../../utils/not-for-preview';

import ShareOverlay from '../../../containers/share-overlay/share-overlay';
import videoCardsBiLogger from './bi-loggers/video-cards-bi-logger';
import {
  pauseVideo,
  playVideo,
  resetPlaybackStatus,
} from '../../../redux/actions/player/change-playback-status';
import { setSavedTime } from '../../../redux/actions/player/set-saved-time';
import { hidePlayerUI, showPlayerUI } from '../../../redux/actions/player/ui';
import { setOverlayContent } from '../../../redux/actions/player/set-overlay-content';
import { toggleShareVideoOverlay } from '../../../redux/actions/share-overlay';
import { canShowComments, hasNewComments } from '../../../selectors/comments';
import { getCurrentSiteUser } from '../../../selectors/current-site-user';
import { isShareOverlayOpen } from '../../../selectors/share-overlay';
import { getVideoCardsList } from '../../../selectors/video-cards';
import { setCommentsOpen } from '../../../redux/actions/comments';

import VideoCardsWrapper from './components/video-cards-wrapper-desktop/video-cards-wrapper-desktop';
import AnnotoHandlers from './components/annoto-handlers/annoto-handlers';

import AnimatedOverlayContent from './containers/animated-overlay-content/animated-overlay-content';
import INFO_OVERLAY_CONTENT from './containers/animated-overlay-content/info-overlay-content-enums';
import { LiveVideoUpdaters } from './containers/live-video-updaters/live-video-updaters';
import Loggers from './containers/loggers/loggers';
import PlayableFullScreenManager from 'playable/dist/src/modules/full-screen-manager/full-screen-manager';
import * as viewModeSelectors from '../../../selectors/view-mode';
import { getBaseUrl } from '../../../selectors/api';
import { getPublicApi } from '../../../api/public';
import { RootState } from '../../../redux/root-state';
import type { SettingsModuleConfig } from '@wix/wix-vod-playable-settings';
import {
  playerVideoSettingsMenuOpen,
  playerVideoSettingsMenuClick,
  playerVideoSettingsChange,
} from '@wix/bi-logger-video/v2';
import { withWidgetProps } from '../../../containers/widget-props';

const { logAllCardsButtonClick, logAllCardsCloseButtonClick } =
  videoCardsBiLogger;

class FullScreenManager extends PlayableFullScreenManager {
  enterFullScreen = notForPreview(() => {
    super.enterFullScreen();
  });
}

type Props = any;

type State = any;

const mapStateToProps = (state: RootState, ownProps: Props) => {
  const cardsData = getVideoCardsList(state);
  const videoItem = _.get(ownProps, 'videoItem');
  const channelData = _.get(ownProps, 'channelData');
  const currentSiteUser = getCurrentSiteUser(state);

  return {
    vidiUrls: getVidiUrls(ownProps),
    cardsData,
    canShowAllCards: !_.isEmpty(cardsData),
    canShowComments: canShowComments(
      state,
      videoItem,
      channelData,
      currentSiteUser,
    ),
    downloadUrl: getDownloadUrlGql(ownProps),
    isEditorViewMode: viewModeSelectors.isEditorMode(state),
    isSiteViewMode: viewModeSelectors.isSiteMode(state),
    isPlayerUIVisible: isPlayerUIVisible(state),
    isCommentsEnabled:
      isCommentsEnabled(state) || isCommentsEnabledForLive(state),
    areElementsRounded: areElementsRounded(state),
    isAutoplayed: isAutoplayed(state),
    isInFullScreen: isInFullScreen(state),
    isShareOverlayOpen: isShareOverlayOpen(state),
    isLive: isLiveVideo(videoItem),
    canAccessFullVideo: canAccessFullVideo(
      channelData,
      videoItem,
      currentSiteUser,
    ),
    isDownloadAllowed: isDownloadAllowed(
      channelData,
      videoItem,
      currentSiteUser,
    ),
    isPlayableVideo: canPlayFullOrPreview(
      channelData,
      videoItem,
      currentSiteUser,
    ),
    currentSiteUser,
    isSingleVideo: isSingleVideo(state),
    canDownloadVideo: isDownloadEnabled(state, videoItem.id),
    instance: getInstance(state),
    baseUrl: getBaseUrl(state),
    hasNewComments: hasNewComments(state),
    overlayContent: getOverlayContent(state),
    isRTL: isRTL(state),
    qualityEnabled: showVideoQuality(state),
    speedEnabled: showVideoPlaybackSpeed(state),
  };
};

const mapDispatchToProps = {
  toggleShareVideoOverlay,
  showPlayerUI,
  hidePlayerUI,
  playVideo,
  pauseVideo,
  resetPlaybackStatus,
  setSavedTime,
  setCommentsOpen,
  setOverlayContent,
  logBi,
  logAllCardsButtonClick,
  logAllCardsCloseButtonClick,
};

class InternalPlayer extends React.Component<Props, State> {
  static propTypes = {
    bi: PropTypes.object,
    appSettings: PropTypes.object,
    channelData: PropTypes.object,
    videoItem: PropTypes.object,
    instance: PropTypes.string,
    baseUrl: PropTypes.string,

    vidiUrls: PropTypes.array,
    className: PropTypes.string,
    mobileMode: PropTypes.bool,
    playAtFullScreen: PropTypes.bool,
    playInline: PropTypes.bool,

    width: PropTypes.number,
    height: PropTypes.number,

    preload: PropTypes.string,

    onPlayRequest: PropTypes.func,
    isEditorViewMode: PropTypes.bool,
    isSiteViewMode: PropTypes.bool,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnded: PropTypes.func,

    onError: PropTypes.func,
    onSubscribeRequest: PropTypes.func,
    onPurchaseRequest: PropTypes.func,
    onRentRequest: PropTypes.func,
    onFullScreenChanged: PropTypes.func,
    onShareRequest: PropTypes.func,
    onVideoInfoShow: PropTypes.func,
    downloadVideo: PropTypes.func,
    onVolumeChange: PropTypes.func,
    onMuteChange: PropTypes.func,

    isAutoplayed: PropTypes.bool,
    isVideoPlayRequested: PropTypes.bool,
    isVideoPauseRequested: PropTypes.bool,
    isVideoPlaying: PropTypes.bool,
    isVideoPaused: PropTypes.bool,
    isShareOverlayOpen: PropTypes.bool,
    isLive: PropTypes.bool,
    isInFullScreen: PropTypes.bool,
    isPlayableVideo: PropTypes.bool,
    muted: PropTypes.bool,
    canAccessFullVideo: PropTypes.bool,
    isDownloadAllowed: PropTypes.bool,

    cardsData: PropTypes.array,

    // actions panel config
    areElementsRounded: PropTypes.bool,
    canShowFullInfo: PropTypes.bool,
    canShareVideo: PropTypes.bool,
    canShowAllCards: PropTypes.bool,
    canShowTitle: PropTypes.bool,
    canShowBuyRentButton: PropTypes.bool,
    canShowComments: PropTypes.bool,
    isCommentsEnabled: PropTypes.bool,
    canDownloadVideo: PropTypes.bool,
    fillAllSpace: PropTypes.bool,

    toggleShareVideoOverlay: PropTypes.func,
    showPlayerUI: PropTypes.func,
    hidePlayerUI: PropTypes.func,
    playVideo: PropTypes.func,
    pauseVideo: PropTypes.func,
    resetPlaybackStatus: PropTypes.func,
    abortPlayVideo: PropTypes.func,
    currentSiteUser: PropTypes.object,
    isInLightbox: PropTypes.bool,
    isSingleVideo: PropTypes.bool,
    setCommentsOpen: PropTypes.func,
    hasNewComments: PropTypes.bool,
    setOverlayContent: PropTypes.func,
    overlayContent: PropTypes.string,
    isRTL: PropTypes.bool,
  };

  static defaultProps = {
    onPlay: _.noop,
    onPause: _.noop,
    onEnded: _.noop,

    onError: _.noop,
    onFullScreenChanged: _.noop,

    onShareRequest: _.noop,
    onVideoInfoShow: _.noop,
    onVolumeChange: _.noop,

    width: 640,
    height: 360,

    preload: 'none',

    onPlayRequest: _.noop,
    canShowTitle: true,
    canShowBuyRentButton: true,
    isPlayableVideo: true,
    muted: false,
  };

  state: State = {
    VideoPlayerWrapper: null,
    VODplayer: null,
    vidiUrls: null,
  };

  loadPlayerPromise: any;
  isUnmounted: boolean = false;
  isVideoElementValidated: boolean = false;

  componentDidMount() {
    this.loadPlayerPromise = makeCancelable(loadVODPlayer());
    this.loadPlayerPromise.promise
      .then((VODplayer: any) => {
        this.setState({ VODplayer });
      })
      .catch((e: any) => {
        if (e.isCanceled) {
          return;
        }
        return Promise.reject(e);
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !this.state.vidiUrls ||
      !_.isEqual(this.props.vidiUrls, nextProps.vidiUrls)
    ) {
      this.setState({
        vidiUrls: nextProps.vidiUrls,
      });
    }

    this.shouldCloseOverlay(nextProps);
  }

  componentWillUnmount() {
    this.loadPlayerPromise.cancel();
    this.isUnmounted = true;
  }

  shouldCloseOverlay(nextProps: Props) {
    const {
      videoItem: { id },
      isVideoPlaying,
      isShareOverlayOpen,
    } = nextProps;

    const isPlayStarted = isVideoPlaying && !this.props.isVideoPlaying;
    const isVideoIdChanged = this.props.videoItem.id !== id;
    const hasClosedOverlay =
      !isShareOverlayOpen && this.props.isShareOverlayOpen;

    if (isPlayStarted || isVideoIdChanged || hasClosedOverlay) {
      this.closeOpenOverlays();
    }
  }

  onEnded = (...args: any[]) => {
    this.closeOpenOverlays();
    this.props.onEnded(args);
  };

  onPlayerInit = (player: any) => {
    if (this.isUnmounted) {
      return;
    }

    this.setState({
      player,
      eventEmitter: player._defaultModules.eventEmitter,
      engine: player._defaultModules.engine,
    });
  };

  getBuyRentButtonText() {
    const {
      channelData,
      videoItem,
      t,
      i18n: { language },
    } = this.props;
    return getBuyRentTextForPlayer(
      { channelData, videoItem } as any,
      t,
      language,
    );
  }

  getVideoOwnershipStatus() {
    const { videoItem } = this.props;
    return getOwnershipStatus(videoItem);
  }

  reportAnomaly = () => {};

  handleOverlayClose = () => {
    if (this.props.overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS) {
      this.props.logAllCardsCloseButtonClick();
    }

    this.closeOpenOverlays();
  };

  closeOpenOverlays = () => {
    this.props.showPlayerUI();
    if (this.props.overlayContent) {
      this.props.setOverlayContent(null);
    }
  };

  openShareOverlay = () => {
    const { toggleShareVideoOverlay, hidePlayerUI } = this.props;
    hidePlayerUI();
    toggleShareVideoOverlay();
  };

  openVideoInfoOverlay = () => {
    this.props.hidePlayerUI();
    this.props.setOverlayContent(INFO_OVERLAY_CONTENT.VIDEO_INFO);
  };

  handleAllCardsButtonClick = () => {
    this.props.hidePlayerUI();
    this.props.setOverlayContent(INFO_OVERLAY_CONTENT.ALL_CARDS);

    this.props.logAllCardsButtonClick();
  };

  getBuyClickHandler = () => {
    const { videoItem, channelData } = this.props;

    // @ts-expect-error
    const status = getFullAccessActionName(channelData, videoItem);

    switch (status) {
      case FULL_ACCESS_ACTION_NAMES.PRICING_PLAN:
        return this.proxySubscribeRequest;
      case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
        return this.proxySubscribeRequest;
      case FULL_ACCESS_ACTION_NAMES.RENT:
        return this.proxyRentRequest;
      default:
        return this.proxyPurchaseRequest;
    }
  };

  proxySubscribeRequest = () => {
    const { onSubscribeRequest } = this.props;
    onSubscribeRequest();
  };

  proxyPurchaseRequest = () => {
    const { onPurchaseRequest } = this.props;
    onPurchaseRequest();
  };

  proxyRentRequest = () => {
    const { onRentRequest } = this.props;
    onRentRequest();
  };

  onPlayAbort = () => {
    // todo add bi
    const {
      resetPlaybackStatus,
      abortPlayVideo,
      videoItem,
      isInLightbox,
      isLive,
    } = this.props;

    if (isInLightbox) {
      resetPlaybackStatus(videoItem.id);
    } else if (isLive) {
      abortPlayVideo(videoItem.id);
    }
  };

  handleDownloadVideo = () => {
    const { isLive, instance, videoItem, channelData, baseUrl } = this.props;
    this.props.logBi('widget.download.video.clicked', {
      channelId: channelData.id,
      videoId: videoItem.id,
      videoType: isOwned(videoItem) ? 'purchased' : 'free',
      isLive,
      duration: videoItem.durationSec * 1000,
    });
    const publicApi = getPublicApi();

    Promise.all([
      this.props.downloadUrl,
      publicApi.videoService.getDownloadUrl(
        videoItem.itemId,
        channelData.id,
        instance,
        baseUrl,
      ),
    ]).then((urls) => {
      const downloadUrl = urls[0] || urls[1];

      window.open(downloadUrl, '_blank');
    });
  };

  getFullScreenShareOverlay() {
    const { channelData, videoItem, mobileMode, isInFullScreen } = this.props;

    if (mobileMode) {
      return null;
    }

    if (!isInFullScreen) {
      return null;
    }

    return (
      <ShareOverlay
        key={`share-${videoItem.id}`}
        channelData={channelData}
        videoItem={videoItem}
      />
    );
  }

  getActionButtonLabels() {
    return {
      videoInfoFullText: this.props.t(
        'widget.action-button-labels.video-info-full',
      ),
      videoInfoShortText: this.props.t(
        'widget.action-button-labels.video-info-short',
      ),
      shareVideoFullText: this.props.t(
        'widget.action-button-labels.share-video-full',
      ),
      shareVideoShortText: this.props.t(
        'widget.action-button-labels.share-video-short',
      ),
      showAllCardsFullText: this.props.t(
        'widget.action-button-labels.show-all-cards-full',
      ),
      showAllCardsShortText: this.props.t(
        'widget.action-button-labels.show-all-cards-short',
      ),
      commentsFullText: this.props.t(
        'widget.action-button-labels.show-comments-full',
      ),
      commentsShortText: this.props.t(
        'widget.action-button-labels.show-comments-short',
      ),
    };
  }

  getVideoCardsAccessibilityLabels() {
    return {
      facebookButtonTitle: this.props.t(
        'widget.accessibility.action-cards.facebook-like-button',
      ),
      youtubeButtonTitle: this.props.t(
        'widget.accessibility.action-cards.youtube-subscribe-button',
      ),
      twitterButtonTitle: this.props.t(
        'widget.accessibility.action-cards.twitter-follow-button',
      ),
    };
  }

  handleCommentsButtonClick = () => {
    this.state.player.showAnnoto();
  };

  validateVideoElement() {
    // this is here to perform an action on video element on user action, so browser will allow further autoplay
    const { player } = this.state;

    if (this.isVideoElementValidated || !player || player._destroyed) {
      return;
    }

    const videoElement = player._defaultModules.engine.getElement();

    if (!videoElement) {
      return;
    }

    videoElement.load();
    this.isVideoElementValidated = true;
  }

  render() {
    const {
      // video player config
      mobileMode,
      width,
      height,
      isAutoplayed,
      isVideoPlayRequested,
      isVideoPlaying,
      isVideoPaused,
      isVideoPauseRequested,
      playAtFullScreen,
      playInline,
      onError,
      videoItem,
      channelData,
      onPlay,
      onPause,
      onFullScreenChanged,
      cardsData,
      preload,
      isLive,
      isPlayableVideo,
      isDownloadAllowed,
      canAccessFullVideo,
      canDownloadVideo,
      muted,
      overlayContent,
      onVolumeChange,
      onMuteChange,
      isRTL,
      fillAllSpace,

      // actions panel config
      areElementsRounded,
      canShowFullInfo,
      canShareVideo,
      canShowAllCards,
      canShowTitle,
      canShowBuyRentButton,
      canShowComments,
      isCommentsEnabled,
      hasNewComments,

      // animated content config
      onShareRequest,
      isEditorViewMode,
      isSiteViewMode,
      t,
      qualityEnabled,
      speedEnabled,
      bi,
    } = this.props;

    const vidiUrls = this.state.vidiUrls;

    const { engine, eventEmitter, player, VODplayer } = this.state;

    if (!isPlayableVideo) {
      return null;
    }
    if (!VODplayer) {
      return <Spinner />;
    }

    if (isVideoPlayRequested && _.isEmpty(vidiUrls)) {
      this.validateVideoElement();
    }

    const buyRentButtonText = this.getBuyRentButtonText();
    const nextButtonAriaLabel = this.props.t(
      'widget.accessibility.action-cards.all-cards.next-page',
    );

    const prevButtonAriaLabel = this.props.t(
      'widget.accessibility.action-cards.all-cards.previous-page',
    );
    const getSlideAriaLabel = (variables: any) =>
      this.props.t(
        'widget.accessibility.action-cards.all-cards.slide-contents',
        variables,
      );

    const isAnnotoModuleEnabled =
      (isCommentsEnabled && !mobileMode) || isEditorViewMode;

    const settingsModuleConfig: SettingsModuleConfig = {
      fontFamily: 'Madefor',
      texts: {
        normalSpeed: t('shared.playerSettings.speed.normalSpeed'),
        menuSpeedTitle: t('shared.playerSettings.speed.title'),
        buttonTooltip: t('shared.playerSettings.tooltip'),
        menuQualityTitle: t('shared.playerSettings.quality.title'),
        autoQualityTitle: t('shared.playerSettings.quality.auto'),
      },
      qualityEnabled,
      speedEnabled,
      onMenuClicked: (target: string) => {
        bi.report(
          playerVideoSettingsMenuClick({
            origin: 'widget',
            channelID: channelData?.id,
            videoID: videoItem?.id,
            target,
          }),
        );
      },
      onPopoverVisibilityChange: (visible) => {
        if (visible) {
          bi.report(
            playerVideoSettingsMenuOpen({
              origin: 'widget',
              channelID: channelData?.id,
              videoID: videoItem?.id,
            }),
          );
        }
      },
      onSpeedChange: (value: number) => {
        bi.report(
          playerVideoSettingsChange({
            origin: 'widget',
            channelID: channelData?.id,
            videoID: videoItem?.id,
            target: 'speed',
            value: String(value),
          }),
        );
      },
      onQualityChange: (value: string) => {
        bi.report(
          playerVideoSettingsChange({
            origin: 'widget',
            channelID: channelData?.id,
            videoID: videoItem?.id,
            target: 'quality',
            value,
          }),
        );
      },
    };

    return (
      <VODplayer
        width={width}
        height={height}
        preload={preload}
        mobileMode={mobileMode}
        vidiUrls={vidiUrls}
        videoItem={videoItem}
        channelData={channelData}
        isVideoPlayRequested={!_.isEmpty(vidiUrls) && isVideoPlayRequested}
        isVideoPauseRequested={isVideoPauseRequested}
        playAtFullScreen={playAtFullScreen}
        playInline={playInline}
        texts={getPlayerTranslations()}
        canShowTitle={canShowTitle}
        onPlay={onPlay}
        onPause={onPause}
        onError={onError}
        canDownload={
          canDownloadVideo && isDownloadAllowed && !mobileMode && !isLive
        }
        onDownloadClick={this.handleDownloadVideo}
        muted={muted}
        onPlayAbort={this.onPlayAbort}
        onEnded={this.onEnded}
        onAnomalyDetected={this.reportAnomaly}
        onFullScreenChanged={onFullScreenChanged}
        onInit={this.onPlayerInit}
        onMuteChange={onMuteChange}
        onVolumeChange={onVolumeChange}
        isAnnotoModuleEnabled={isAnnotoModuleEnabled}
        modules={{
          fullScreenManager: FullScreenManager,
        }}
        isRTL={isRTL}
        fillAllSpace={fillAllSpace}
        settingsModuleConfig={settingsModuleConfig}
        showSettings
      >
        {!mobileMode && (
          <ActionsPanel
            videoItem={videoItem}
            isFocusable={isVideoPlaying || isVideoPaused}
            areElementsRounded={areElementsRounded}
            canShowBuyRentButton={
              canShowBuyRentButton && Boolean(buyRentButtonText)
            }
            canShowTitle={canShowTitle}
            canShowAllCardsButton={canShowAllCards}
            canShowCommentsButton={canShowComments}
            shouldShowCommentsPimple={hasNewComments}
            canShowFullInfo={canShowFullInfo}
            canShareVideo={canShareVideo}
            onShareVideoShow={this.openShareOverlay}
            onBuyRentButtonClick={this.getBuyClickHandler()}
            onAllCardsButtonClick={this.handleAllCardsButtonClick}
            onCommentsButtonClick={this.handleCommentsButtonClick}
            // @ts-expect-error
            videoOwnershipStatus={this.getVideoOwnershipStatus()}
            onVideoInfoShow={this.openVideoInfoOverlay}
            buyRentButtonText={buyRentButtonText}
            {...this.getActionButtonLabels()}
            infoButtonAriaLabel={this.props.t(
              'widget.accessibility.show-video-info',
            )}
            shareButtonAriaLabel={this.props.t('widget.accessibility.share')}
            allCardsButtonAriaLabel={this.props.t(
              'widget.accessibility.all-cards',
            )}
            commentsButtonAriaLabel={this.props.t(
              'widget.accessibility.video-chat',
            )}
            isRTL={isRTL}
          />
        )}

        {!mobileMode && (
          <AnimatedOverlayContent
            videoItem={videoItem}
            channelData={channelData}
            overlayContent={overlayContent}
            onShareRequest={onShareRequest}
            onClose={this.handleOverlayClose}
            allCardsOverlayContent={
              <VideoCardsWrapper
                thumbnailSize={300}
                // @ts-expect-error
                biOrigin={VIDEO_CARDS_BI_ORIGIN.ALL_CARDS}
                cardsData={cardsData}
              >
                {({ cardsData, onCardClick }) => (
                  <AllVideoCards
                    cardsData={cardsData}
                    onCardClick={onCardClick}
                    getSlideAriaLabel={getSlideAriaLabel}
                    prevButtonAriaLabel={prevButtonAriaLabel}
                    nextButtonAriaLabel={nextButtonAriaLabel}
                    labels={this.getVideoCardsAccessibilityLabels()}
                  />
                )}
              </VideoCardsWrapper>
            }
          />
        )}

        {!mobileMode && player && !player._destroyed && (
          <VideoCardsWrapper
            thumbnailSize={300}
            // @ts-expect-error
            cardsData={cardsData}
            player={player}
            biOrigin={VIDEO_CARDS_BI_ORIGIN.VIDEO}
          >
            {({ cardsData, onCardClick }) => (
              <VideoCards
                cardsData={cardsData}
                player={player}
                onCardClick={onCardClick}
                labels={this.getVideoCardsAccessibilityLabels()}
              />
            )}
          </VideoCardsWrapper>
        )}

        {!mobileMode && (
          <PlayerUIVisibilityManager
            isPlayerUIVisible={this.props.isPlayerUIVisible}
          />
        )}

        {player && !player._destroyed && (
          <Loggers
            engine={engine}
            eventEmitter={eventEmitter}
            videoItem={videoItem}
            channelData={channelData}
            isVideoPlaying={isVideoPlaying}
            isLive={isLive}
            isAutoplayed={isAutoplayed}
          />
        )}

        {player && !player._destroyed && isLive && (
          <LiveVideoUpdaters
            eventEmitter={eventEmitter}
            videoItem={videoItem}
            channelId={channelData.id}
            canAccessFullVideo={canAccessFullVideo}
            isSiteMode={isSiteViewMode}
          />
        )}

        {this.getFullScreenShareOverlay()}

        {player && isCommentsEnabled && !mobileMode && (
          <AnnotoHandlers
            player={player}
            videoItem={videoItem}
            channelData={channelData}
            mobileMode={mobileMode}
          />
        )}
      </VODplayer>
    );
  }
}

export default withTranslation()(
  withBi(connect(mapStateToProps, mapDispatchToProps)(InternalPlayer)),
);
